import { TrackerThemeAttributes } from "./TrackerThemeAttributes";

export const defaultTheme: TrackerThemeAttributes = {
  colorPrimary: "rgba(43,113,212,1.0)",
  colorTextDefault: "rgba(49,53,53,1.0)",
  colorTextDisabled: "rgba(106,113,113,1.0)",
  colorFillDefault: "rgba(255,255,255,1.0)",
  colorFillDisabled: "rgba(247,249,249,1.0)",
  colorBorder: "rgba(0,0,0,15%)",
  colorError: "rgba(205,54,64,1.0)",
};
