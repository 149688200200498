import { TrackerThemeAttributes } from "./TrackerThemeAttributes";

export const greenTheme: TrackerThemeAttributes = {
  colorPrimary: "rgba(0,128,88,1.0)",
  colorTextDefault: "rgba(49,53,53,1.0)",
  colorTextDisabled: "rgba(106,113,113,1.0)",
  colorFillDefault: "rgba(255,255,255,1.0)",
  colorFillDisabled: "rgba(175,255,231,1.0)",
  colorBorder: "rgba(0,77,52,1.0)",
  colorError: "rgba(205,54,64,1.0)",
};
