import { extendTheme, theme as base } from "@chakra-ui/react";
import { AppearanceVariable, TrackerThemeAttributes } from "./themes";

const fonts = {
  heading: `Inter, ${base.fonts?.heading}`,
  body: `Inter, ${base.fonts?.heading}`,
};

const fontSizes = {
  xs: "0.5rem",
  sm: "0.625rem",
  md: "0.75rem",
  lg: "0.875rem",
  xl: "1rem",
  "2xl": "1.125rem",
  "3xl": "1.25rem",
  "4xl": "1.5rem",
  "5xl": "1.875rem",
  "6xl": "2rem",
  "7xl": "3rem",
  "8xl": "3.75rem",
  "9xl": "4.5rem",
  "10xl": "6rem",
  "11xl": "8rem",
};

const generateCssVariables = (theme: TrackerThemeAttributes) => {
  const variables = {};
  Object.keys(theme).forEach((key) => {
    variables[key] = `var(--mt-${key})`;
  });
  return variables;
};

// This maps our variables to chakra's variables
// ex: --chakra-colors-colorPrimary: var(--mt-colorPrimary)
const colors = generateCssVariables(AppearanceVariable);

const chakraOverrides = {
  fonts,
  fontSizes,
  colors,
  initialColorMode: "system",
  useSystemColorMode: false,
};

const chakraConfig = extendTheme(chakraOverrides);

export default chakraConfig;
