export enum AppearanceVariable {
  colorPrimary = "colorPrimary",
  colorTextDefault = "colorTextDefault",
  colorTextDisabled = "colorTextDisabled",
  colorFillDefault = "colorFillDefault",
  colorFillDisabled = "colorFillDisabled",
  colorBorder = "colorBorder",
  colorError = "colorError",
}

export interface TrackerThemeAttributes {
  [AppearanceVariable.colorPrimary]: string;
  [AppearanceVariable.colorTextDefault]: string;
  [AppearanceVariable.colorTextDisabled]: string;
  [AppearanceVariable.colorFillDefault]: string;
  [AppearanceVariable.colorFillDisabled]: string;
  [AppearanceVariable.colorBorder]: string;
  [AppearanceVariable.colorError]: string;
}
