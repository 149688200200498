import { defaultTheme } from "./default";
import { greenTheme } from "./green";
import { TrackerThemeAttributes } from "./TrackerThemeAttributes";

export enum TrackerTheme {
  default,
  green,
}

export const getThemeAttributes = (
  theme: TrackerTheme
): TrackerThemeAttributes => {
  switch (theme) {
    case TrackerTheme.green:
      return greenTheme;
    default:
      return defaultTheme;
  }
};
