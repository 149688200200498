import React from "react";
import { GraphQLError } from "graphql/error/GraphQLError";
import { useMessenger } from "@pinkairship/use-messenger";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

function getCSRFToken(): string | null {
  const element = document.querySelector("meta[name=csrf-token]");

  if (element instanceof HTMLMetaElement) {
    return element.content;
  }

  return null;
}

const UNEXPECTED_ERROR_MSG =
  "Sorry an unexpected error occurred. If the error persists, please contact your account manager.";

function ApolloCustomProvider({ children }: { children: React.ReactNode }) {
  const { addMessage } = useMessenger();
  const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        const errorMessage = (graphQLErrors as GraphQLError[])
          .map((error) => error.message)
          .join(", ");
        addMessage(errorMessage);
      } else {
        addMessage(UNEXPECTED_ERROR_MSG);
      }
    } else if (networkError) {
      // "TypeError: Failed to fetch" errors are also thrown and noisy when navigating away during an inflight req
      addMessage(UNEXPECTED_ERROR_MSG);
    }
  });

  const httpLink = createUploadLink({
    uri: "/graphql",
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": getCSRFToken(),
    },
  });

  const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({}),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloCustomProvider;
