import React from "react";
import { createGlobalStyle } from "styled-components";
import { ChakraProvider } from "@chakra-ui/react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import chakraConfig from "../chakraConfig";
import {
  TrackerTheme,
  TrackerThemeAttributes,
  getThemeAttributes,
} from "../themes";

type Rules = {
  [key: string]: string | Rules;
};

type CSSVariables = {
  [key: string]: string;
};

export type UserAppearance = {
  theme?: TrackerTheme;
  variables?: CSSVariables;
  rules?: Rules;
};

interface MTChakraProviderProps {
  children?: React.ReactNode;
  appearance?: UserAppearance;
}

const emotionCache = createCache({
  key: "emotion-css-cache",
  prepend: true, // ensures styles are prepended to the <head>, instead of appended
});

export const mtCssVariables = (cssAttributes: TrackerThemeAttributes) => {
  let str = "";

  Object.entries(cssAttributes).forEach(([key, value]: [string, string]) => {
    str += `--mt-${key}: ${value};`;
  });
  return str;
};

const setTheme = (appearance: UserAppearance) => {
  const theme = {
    ...getThemeAttributes(appearance?.theme || TrackerTheme.default),
    ...appearance.variables,
  };

  const ThemeStyles = createGlobalStyle`
  :root{
    ${mtCssVariables(theme)}
  }
  ${appearance.rules}
  `;
  return <ThemeStyles />;
};

export default function MTChakraProvider({
  children,
  appearance,
}: MTChakraProviderProps) {
  return (
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={chakraConfig}>
        {appearance && setTheme(appearance)}
        {children}
      </ChakraProvider>
    </CacheProvider>
  );
}
