import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    app: {
      "app.nav.main.projects": "Projects",
      "app.nav.main.bookings": "Bookings",
      "app.nav.main.people": "People",
      "app.nav.main.ledgers": "Ledgers",
      "app.nav.main.revenue_sources": "Revenue Sources",
      "app.nav.main.servicers": "Servicers",
      "app.nav.main.users": "Users",
      "app.nav.main.calendars": "Calendars",
      "app.nav.main.dashboard": "Home",
      "app.nav.menu.reset_password": "Reset Password",
      "app.nav.menu.sign_out": "Sign Out",
      "app.nav.menu.settings": "Settings",
      "app.nav.menu.colorMode.dark": "Dark",
      "app.nav.menu.colorMode.light": "Light",
      "app.nav.menu.colorMode.system": "System",
      "app.nav.menu.colorMode": "Color Mode",
      "app.none": "None",
      "app.cancel": "Cancel",
      "login.index.login_title": "Login",
      "login.index.login_button": "Login",
      "login.index.email": "Email",
      "login.index.password": "Password",
      "login.index.remember_me": "Remember Me",
      "login.index.register_button": "Register",
      "login.index.forgot_password_button": "Forgot Password?",
      "login.index.invalid_credentials": "Invalid Credentials",
      "login.index.invalid_credentials_description":
        "Please check your email and password and try again.",
      "login.index.error": "Error",
      "login.index.error_description":
        "There was an error logging you in. Please try again.",
      "login.index.email_required": "Email is required",
      "login.index.password_required": "Password is required",
      "login.index.password_min_length":
        "Password must be at least 8 characters",
      "login.index.password_max_length":
        "Password must be at most 128 characters",
      "login.index.dark_mode": "Enable Dark Mode",
      "login.index.rememberMe": "Remember Me",
    },
    assignments: {
      "index.header.title": "Assignments",
      "table.header.service_date": "Service Date",
      "table.header.location": "Location",
      "table.header.name": "Name",
      "table.header.accepted": "Accepted",
      "table.header.reject": "Reject?",
      "table.header.instructions": "Instructions",
      "table.none_found": "No Assignments Found",
      "table.body.accept": "Accept",
      "table.body.addToCalendar": "Add To Calendar",
      "table.body.link.google": "Google Calendar",
      "table.body.link.office": "Office 365 Calendar",
      "table.body.link.outlook": "Outlook.com Calendar",
      "table.body.reject": "Reject",
    },
    calendars: {
      "header.status.all": "Showing All Projects",
      "header.status.some": "Showing: {{selected}}",
      "header.status.none": "No Projects Selected",
      "and.n.more": "And {{remainderBookings}} more...",
      "bookings.in.day": "Bookings",
      "view.bookings": "Manage...",
      "project.name": "Project",
      "drawer.settings.title": "Calendar Settings",
      "drawer.settings.options.show_strikes": "Display Strikes",
      "drawer.settings.options.show_strikes_help_text":
        "Show strikes on past calendar days and bookings.",

      "drawer.settings.options.select_projects": "Display Bookings by Project",
    },
    resetPassword: {
      "index.title": "Reset Password",
      "index.currentPassword": "Current Password",
      "index.password": "New Password",
      "index.passwordConfirm": "Confirm Password",
      "index.submit": "Submit",
    },
    booking: {
      "booking.index.header.title": "Booking",
      "booking.index.details.title": "Details",
      "booking.index.details.heading_additional": "Additional Details",
      "booking.index.details.lessee": "Lessee",
      "booking.index.details.project": "Project",
      "booking.index.details.dates": "Dates",
      "booking.index.details.reason": "Booking Reason",
      "booking.index.details.rating": "Rating",
      "booking.index.details.originator": "Booking Source",
      "booking.index.details.none": "None",
      "booking.index.details.yes": "Yes",
      "booking.index.details.no": "No",
      "booking.show.header": "{{projectName}} booking for <2>{{name}}</2>",
      "booking.show.edit": "<0>Edit Booking</0>",
      "booking.show.status": "Status: {{status}}",
      "booking.show.dates": "Dates",
      "booking.show.details.date_start": "Start At",
      "booking.show.details.date_end": "End At",
      "booking.show.assignee": "Assignee",
      "booking.show.instructions": "Instructions",
      "Booking Reason": "Booking Reason",
      "booking.show.details": "Details",
      "Revenue Source": "Revenue Source",
      "booking.show.revenues": "Revenues",
      "booking.index.header": "Bookings",
      "booking.index.table.lessee": "Lessee",
      "booking.index.table.status": "Status",
      "booking.index.table.start_date": "Start Date",
      "booking.index.table.end_date": "End Date",
      "booking.index.table.project": "Project",
      "booking.index.table.revenue_source": "Revenue Source",
      "bookings.index.header.new_booking": "New Booking",
      "booking.create.header": "New Booking",
      "booking.edit.header": "Edit Booking",
      "booking.create.select_project": "Select Project",
      "booking.create.select_booking_reason": "Select Booking Reason",
      "booking.create.errors.end_at": "End At cannot be before Start At",
      "booking.create.help_text.start_at": "Start At before Today",
      "booking.create.new_booking_reason": "Booking Reason",
      "booking.create.new_lessee": "Lessee",
      "booking.create.drawer.new_reason": "Add New Booking Reason",
      "booking.create.drawer.new_lessee": "Add New Lessee",
      "booking.create.select_lessee": "Select Lessee",
      "booking.create.submit": "Create Booking",
      "booking.edit.submit": "Update Booking",
      "booking.create.view_after_creation": "View Booking after Creation",
      "booking.create.success_toast.title": "Booking created successfully!",
      "booking.edit.success_toast.title": "Booking updated successfully!",
      "booking.create.additionalSettings": "Additional Information",
      "booking.table.currentAndUpcoming": "Current & Upcoming",
      "booking.table.current": "Current",
      "booking.table.upcoming": "Upcoming",
      "booking.table.stale": "Stale Booking Statuses",
      "booking.table.header.stale": "Stale Bookings",
      "booking.table.header.assignments": "Assignments",
      "booking.table.past": "Past",
      "booking.table.cancelled": "Cancelled",
      "booking.table.none_found": "No {{title}} bookings found.",
      "select revenue source": "Select Revenue Source",
      Amount: "Amount",
      select_new_lessee: "Select New Lessee",
      name: "Name",
      email: "Email",
      phone_number: "Phone Number",
      description: "Description",
      Submit: "Submit",
      View: "View",
      Search: "Search by Lessee Name",
      "Search for Lessee": "Search by name, email, or phone",
      Project: "Project",
      Lessee: "Lessee",
      "Start At": "Start At",
      "End At": "End At",
      "Select Date and Time": "Select Date and Time",
      Assignments: "Assignments",
      "booking.show.acceptedAssignment": "Assignment Accepted",
      "booking.show.expectedCompletionDate": "Expected Completion Date",
    },
    dashboard: {
      "dashboard.index.header.title": "Dashboard",
      "dashboard.index.content.welcome": "Welcome to the Tracker App!",
      "dashboard.index.content.description":
        "Get started by visiting one of the pages listed below.",
      "dashboard.index.content.nav.projects": "Projects",
      "dashboard.index.content.nav.bookings": "Bookings",
      "dashboard.index.content.nav.people": "People",
      "dashboard.index.content.nav.ledgers": "Ledgers",
      "dashboard.index.content.nav.revenue_sources": "Revenue Sources",
      "dashboard.index.content.nav.servicers": "Servicers",
      "dashboard.index.content.nav.users": "Users",
      "dashboard.index.content.nav.calendars": "Calendars",
    },
    inputs: {
      Today: "Today",
      Week: "Week",
      Month: "Month",
      Quarter: "Quarter",
      HalfYear: "Half Year",
      Year: "Year",
      Gt: "Greater Than",
      Gte: "Greater or Equal",
      Eq: "Equal",
      Lt: "Less Than",
      Lte: "Less or Equal",
    },
    translations: {
      "site.banner": "AccuLabor",
      "error.update.failed":
        "An error occurred while updating. Please try again.",
      "error.create.failed":
        "An error occurred while creating. Please try again.",
      "error.delete.failed":
        "An error occurred while deleting. Please try again.",
      "delete.item.confirm": "Confirm Delete",
      "delete.item.confirm.body":
        "Are you sure you want to delete this item? This action cannot be undone.",
      "job.label.startDate": "Start Date: {{ date }}",
      "workers.notify":
        "One or more workers not notified. Workers must be notified to start.",
    },
    account: {
      "signin.welcome": "Sign in to Account",
      "signin.email": "Email Address",
      "signin.password": "Password",
      "signin.signin": "Sign In",

      // Admin Signup
      "signup.banner": "Keep Your Workers Off Their Phones",
      "signup.description":
        "Track how long your workers are on their phone and only pay them for the time they actually work. Our app is non-intrusive and privacy centric, tracking only time on the phone.",
      "signup.bullet1": "Tracks user phone usage",
      "signup.bullet2": "Per job time information for each worker",
      "signup.bullet3":
        "Easy to use interface to create jobs and assign workers",
      "signup.bullet4":
        "Leave the worksite with confidence knowing workers are not on their phones",
      "signup.create_account": "Create an Account",
      "signup.name": "Name",
      "signup.confirm_password": "Confirm Password",
      "signup.marketing_optin":
        "I want to receive updates and offers from Acculabor",
      "signup.signup": "Sign Up",

      // Worker Signup

      "signup.worker.preferred_name": "Preferred Name",
      "signup.worker.banner": "Hired for a Job? Sign Up!",
      "signup.worker.description":
        "Our privacy centric app allows you to work with confidence that you will not be shorted pay - you have proof you were off your phone!",
      "signup.worker.bullet1": "Privacy centric - only tracks time in our app",
      "signup.worker.bullet2": "See what jobs you have coming up",
      "signup.worker.bullet3": "Prove that you were not on your phone",
      "signup.worker.bullet4": "Know how much time you have worked at a job",
      "signup.worker.create_account": "Create an Account",
      "signup.worker.name": "Name",

      // Forgot password

      "forgot.banner": "Reset Your Password",
      "forgot.signin": "Back to Signin",
      "forgot.reset": "Reset",
      "forgot.sent.banner": "Password Reset Sent",
      "forgot.sent.instructions":
        "If you have an account you will receive an email with a link to reset your password.",

      // Reset password

      "reset.banner": "Reset Your Password",
      "reset.success.banner": "Your Password Has Been Reset",
      "reset.error.banner": "Your Password Reset Failed",
      "reset.success.instructions": "You can now sign in.",
      "reset.error.instructions": "Please try again",

      // Confirm Email password

      "confirm_email.success.banner": "Your Email Has Been Confirmed",
      "confirm_email.error.banner": "Your Email Confirmation Failed",
      "confirm_email.success.instructions":
        "Your email confirmation is now complete.",
      "confirm_email.error.instructions":
        "Please contact support@accu-labor.com",
    },
    worker: {
      "worker.jobs.banner": "Your Jobs",
      "worker.reject": "Reject Job",
      "worker.delete.confirm.title": "Are you sure?",
      "worker.delete.confirm.body":
        "Once rejected you must be added again by the owner of the job.",
      no_jobs:
        "It looks like you have no jobs currently. Contact your manager to add you to a job.",
    },
    "worker.please_register": {
      banner: "You Must Register",
      instructions:
        "Before you can accept or reject a job, you must first register.",
      register: "Click Here to Register",
      login: "Return to Login",
    },
    "worker.accept": {
      banner: "You Accepted the Job",
      instructions:
        "Congratulations on getting the job! The next step is to download the app and signin to begin your job.",
      login: "Return to Login",
      download: "Download App Now",
    },
    "worker.reject": {
      banner: "You Rejected the Job",
      instructions:
        "We hope you rejected the job intentionally. If not, you must contact your manager to be re-added. If you are worried about how our app works, checkout our downloads page and download the app to get a feel for it.",
      login: "Return to Login",
      download: "Download App Now",
    },
    downloads: {
      banner: "Download the App",
      ios: "Apple App Store",
      google: "Google Play Store",
    },
    genericMessage: {
      banner: "There was a system error",
      "rejected.cannot_accept":
        "You cannot accept a job once it has been rejected. Contact your manager to be added to the job again.",
      unknown:
        "There was an unknown error. If this persists please contact support@accu-labor.com",
      back: "Go Back",
    },
    projects: {
      "projects.index.header": "Projects",
      "projects.index.new_project": "New Project",
      "projects.index.table.name": "Name",
      "projects.index.table.created_at": "Created",
      "projects.index.table.updated_at": "Updated",
    },
    project: {
      "project.index.details.title": "Details",
      "project.index.details.color": "Color",
      "project.index.details.createdAt": "Created At",
      "project.index.details.updatedAt": "Updated At",
      "project.index.header.archived": "(Archived)",
      "project.index.view.title": "View",
      "project.index.view.purchases": "Purchases",
      "project.index.view.bookings": "Bookings",
      "project.index.view.revenues": "Revenues",
      "project.index.view.expenses": "Expenses",
      "project.index.view.inventory": "Inventory",
      "project.index.actions.edit": "Edit",
      "project.index.actions.archive": "Archive",
      "project.index.actions.unarchive": "Unarchive",
      "project.index.table.bookings.title": "All Project Bookings",
      "project.new.heading": "New Project",
      "project.new.description": "Description",
      "project.new.select_project_type": "Select Project Type",
      "project.new.calendarColor": "Select Project Calendar Color",
    },
    people: {
      "index.header": "People",
      "index.new_person": "New Person",
      "index.table.title.lessees": "Lessees",
      "index.table.title.servicers": "Servicers",
      "index.table.name": "Name",
      "index.table.email": "Email",
      "index.table.phone": "Phone",
      "index.table.created_at": "Created",
      "index.table.updated_at": "Updated",
      "index.table.none": "No {{title}} found.",
      "index.table.view": "View",
      "show.header.role.name": "{{role}}: {{name}}",
      "show.details.title": "Details",
      "show.details.name": "Name: {{name}}",
      "show.details.email": "Email: {{email}}",
      "show.details.phone": "Phone: {{phone}}",
      "show.details.createdAt": "Created at: {{createdAt}}",
      "show.details.updatedAt": "Updated at: {{updatedAt}}",
      "show.body.bookings.title": "Bookings",
      "show.actions.edit": "Edit",
      "show.actions.delete": "Delete",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    // debug: process.env.NODE_ENV === 'development',
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
