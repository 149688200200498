import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Datetime in ISO 8601 format */
  Datetime: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** Autogenerated return type of ArchiveProject. */
export type ArchiveProjectPayload = {
  __typename?: 'ArchiveProjectPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The archived project */
  project: Project;
};

/** Autogenerated return type of Assign. */
export type AssignPayload = {
  __typename?: 'AssignPayload';
  /** The created booking assignment */
  bookingAssignment: BookingAssignment;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** The assignments associated with a user. */
export type AssignedBooking = Node & {
  __typename?: 'AssignedBooking';
  /** The date the assignment was accepted. */
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** A list of links to add to the servicers calendar of choice. */
  addToCalendar: Array<Scalars['String']>;
  /** The id of the booking assignment. */
  id: Scalars['ID'];
  /** Any additional details to help understand the assignment */
  instructions?: Maybe<Scalars['String']>;
  /** The project this assignment is attached to */
  location: Scalars['String'];
  /** The name of the assignment */
  name: Scalars['String'];
  /** The date the assignment is expected to be completed on. */
  serviceDate?: Maybe<Scalars['ISO8601DateTime']>;
};

/**
 * The assignments associated with a project. These create the booking assignment
 * that can be used to determine what needs to be done for a given booking.
 */
export type Assignment = Node & {
  __typename?: 'Assignment';
  /** The time the booking was created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The id of the booking assignment. */
  id: Scalars['ID'];
  /** Any additional details to help understand the assignment */
  instructions?: Maybe<Scalars['String']>;
  /** A descriptive name of the assignment. */
  name?: Maybe<Scalars['String']>;
  /** The project this assignment is attached to */
  project: Project;
  /** The time the booking was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The data type to define what associated entity behavior should have for display purposes. */
export type AssociatedEntity = {
  __typename?: 'AssociatedEntity';
  /** The type of display to use. */
  displayType: DisplayTypes;
  /** The entity to display */
  entity: Entity;
  /** If present, will make the text content a link and sets the href to this value. */
  href?: Maybe<Scalars['String']>;
};

/** The data type to define how an async option should behave. */
export type AsyncOption = {
  __typename?: 'AsyncOption';
  /** The value to filter the options by. */
  filter: Scalars['String'];
  /** The key to the query to use for this option. */
  query: Scalars['String'];
};

/**
 * A representation of the booking within the system for your rental project. It
 * contains the information relevant to the timeframe of the booking, as well as
 * acts as the top level umbrella under which all other information about a guest's
 * use of your rental can be placed. For example, information for checkin, related
 * rental packages, assignments, revenues, and messaging with your client will all
 * fall under this root level object.
 */
export type Booking = Node & {
  __typename?: 'Booking';
  /** The assignments and the assignees of the booking. There may be more than one booking assignment per assignment' */
  assignments: Array<BookingAssignment>;
  /** Booking type specific details. */
  bookingDetails: BookingDetailsInterface;
  /** The platform or method from which the booking originated. */
  bookingOriginator: BookingSource;
  /** Reason for booking */
  bookingReason: BookingReason;
  /** The time the booking was created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The time the booking is scheduled to end. */
  endAt: Scalars['ISO8601DateTime'];
  /** The id of the booking. */
  id: Scalars['ID'];
  /** The person you are leasing your rental to. */
  lessee: Lessee;
  /** The rating given to the lessee for this booking. */
  lesseeRating?: Maybe<Scalars['Int']>;
  /** The project for which your booking is created. */
  project: Project;
  /** All revenues attributed to this booking. */
  revenues: Array<Revenue>;
  /** The time the booking is scheduled to start. */
  startAt: Scalars['ISO8601DateTime'];
  /** The status indicating in what state the booking currently is in. */
  status: BookingStatuses;
  /** The time the booking was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
  /** All possible transitions from the current state of the booking. */
  validTransitions: Array<BookingTransitions>;
};

/** The assignments associated with a booking. These may have an assignee, or they may be awaiting assignment. */
export type BookingAssignment = Node & {
  __typename?: 'BookingAssignment';
  /** The time the booking assignemnt was accepted. */
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Any additional instructions for the assignment */
  additionalInstructions?: Maybe<Scalars['String']>;
  /** The time the booking assignemnt was created. */
  assignedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The assignee for the booking if assigned */
  assignee?: Maybe<Servicer>;
  /** The assignment for the booking assignment */
  assignment: Assignment;
  /** The booking for the booking assignment */
  booking: Booking;
  /** The time the booking is expected to be completed. */
  expectedCompletionDate: Scalars['ISO8601DateTime'];
  /** The id of the booking assignment. */
  id: Scalars['ID'];
  /** The time the booking assignment was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Booking. */
export type BookingConnection = {
  __typename?: 'BookingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Booking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Booking details are extra instructions to be had for the booking. */
export type BookingDetailsInput = {
  /** Instructions specific for the lessee and booking. */
  additionalLesseeInstructions?: InputMaybe<Scalars['String']>;
  /** The desitination the watercraft will be put in. */
  destination?: InputMaybe<Scalars['String']>;
  /** Door code to use. Defaults to last 4 of phone number */
  doorCode?: InputMaybe<Scalars['String']>;
  /** Whether or not the safety instructions have been viewed. */
  safetyInstructionsViewed?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the waiver has been signed. */
  waiverSigned?: InputMaybe<Scalars['Boolean']>;
  /** Instructions specific for the watercraft and booking. */
  watercraftInstructions?: InputMaybe<Scalars['String']>;
};

/**
 * A Booking Detail outlines the details specific for the type of booking that has
 * been created. The details for a short term rental property are going to be
 * different from those of a day rental for jetskis, so put the details in the
 * appropriate type.
 */
export type BookingDetailsInterface = {
  /** Instructions specific for this booking specific to the lessee. */
  additionalLesseeInstructions?: Maybe<Scalars['String']>;
  /** The time the details were last created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The id of the booking details */
  id: Scalars['ID'];
  /** Instructions specific for this booking. */
  lesseeInstructions?: Maybe<Scalars['String']>;
  /** The time the details were last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** An edge in a connection. */
export type BookingEdge = {
  __typename?: 'BookingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Booking>;
};

/**
 * Lessees book for multiple reasons. If possible this should be collected to help
 * understand what the use cases for the booking is to help optimize future
 * marketing efforts.
 */
export type BookingReason = Node & {
  __typename?: 'BookingReason';
  /** The id of the booking reason. */
  id: Scalars['ID'];
  /** The name/identifier of the reason */
  title: Scalars['String'];
};

/**
 * BookingSearchParams takes the fields and, for the most part, ANDs the lookups
 * when doing a search. The exception to this is for the date lookups for start_at
 * and end_at. Since many bookings will still want to be shown in a given timeframe
 * because they either end or begin in that timeframe but don't have the beginning
 * or end in that timeframe, then a simple ANDing of those date params will not
 * include them. Instead we want to lookup all dates that might have a booking in
 * that time frame, so we have to do something special. For this reason, without
 * including exclude_bleedover, all start_at and end_at timeframe searches will
 * include any booking that has a date within the range.
 */
export type BookingSearchParams = {
  /** Filter by the originator of the booking. */
  bookingOriginatorId?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by the type of the booking. */
  bookingType?: InputMaybe<Scalars['String']>;
  /** Filter by the end of the booking */
  endAt?: InputMaybe<EqualitySearchInput>;
  /** Query for bookings that start and end within the timeframe only. */
  excludeBleedover?: InputMaybe<Scalars['Boolean']>;
  /** Filter by guest rating. */
  guestRating?: InputMaybe<EqualitySearchInput>;
  /** Filter for all bookings made by a lessee. */
  lesseeId?: InputMaybe<Scalars['ID']>;
  /** Filter by the name of the lessee (partial match). */
  lesseeName?: InputMaybe<Scalars['String']>;
  /** Filter by the project the booking is in. */
  projectId?: InputMaybe<Array<Scalars['ID']>>;
  /** The key to sort by. Accepts dot notation for nested value sorts. */
  sortBy?: InputMaybe<Scalars['String']>;
  /** The name of the query to use to retrieve the entity. */
  sortDirection?: InputMaybe<SortDirectionTypes>;
  /** Filter by the start of the booking. */
  startAt?: InputMaybe<EqualitySearchInput>;
  /** Filter by a list of states for given bookings. */
  state?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * A Booking Source is the platform and/or method through which a booking was
 * obtained. Common platforms are Airbnb, VRBO, Rentme.com, bookings.com, Facebook
 * Marketplace, Craigslist, etc. This can also be a private website or a referral
 * from someone. Whatever the source may be, add a unique identifier to help
 * categorize a booking to the source from whence it came.
 */
export type BookingSource = Node & {
  __typename?: 'BookingSource';
  /** A description of the booking source used to provide context about the source as necessary. */
  description?: Maybe<Scalars['String']>;
  /** The id of the booking source. */
  id: Scalars['ID'];
  /** The name/identifier of the source */
  name: Scalars['String'];
};

export enum BookingStatuses {
  /** Booking has been accepted and no other requirements are needed. */
  Accepted = 'accepted',
  /** A booking that has been cancelled by the lesee. */
  Cancelled = 'cancelled',
  /** A booking that has been cancelled by the lesee after acceptance and after full refund deadline. */
  CancelledPartialRefund = 'cancelled_partial_refund',
  /** A booking that has all work for it completed. */
  Completed = 'completed',
  /** A booking that failed to move past the inquiry or paending acceptance phase. */
  Failed = 'failed',
  /** Booking is currently in progress */
  InProgress = 'in_progress',
  /** The initial value of a booking. Terms are being negotiated at this point. */
  Inquiry = 'inquiry',
  /**
   * A booking that has concluded but has not yet been finalized. If there is
   * additional work to be done in relation to the booking, it is done during this
   * state. For example, cleaners to clean, invoicing for damages, etc.
   */
  Offboarded = 'offboarded',
  /**
   * Indicates that a booking is about to be completed and any instructions for
   * finishing a booking should be done during this time.
   */
  Offboarding = 'offboarding',
  /**
   * Bookings that have been onboarded are ready to begin but have not yet reached
   * the start time. This is an indication that all parties have acknowledged any
   * preparation for the booking has been completed.
   */
  Onboarded = 'onboarded',
  /**
   * Indicates that instructions or additional actions required before booking can
   * begin need to be done. Generally this includes sending checkin information,
   * usage instructions, or driving directions - anything that will make a booking
   * a success and alleviate confusion ahead of time.
   */
  Onboarding = 'onboarding',
  /** Indicates the lesee has more to do before booking is accepted. */
  PendingAcceptance = 'pending_acceptance',
  /** Project owner has rejected the booking for whatever reason. */
  Rejected = 'rejected'
}

export enum BookingTransitions {
  /** Accept a booking without additional terms needed. */
  Accept = 'accept',
  /** Addtional terms have been accepted and booking is now accepted. */
  AcceptedTerms = 'accepted_terms',
  /** Start the booking. */
  Begin = 'begin',
  /** Lesee cancelled the booking. */
  Cancel = 'cancel',
  /** Lesee cancelled the booking but only got a partial refund. */
  CancelWithPartialRefund = 'cancel_with_partial_refund',
  /** Skip the offboarding process and complete a booking. */
  Close = 'close',
  /** Acknowledge that a booking has ended and start any post-booking work. */
  ConcludeOffboarding = 'conclude_offboarding',
  /** Acknowledge that a booking is ready to be started. */
  ConcludeOnboarding = 'conclude_onboarding',
  /** Lesee failed to progress to acceptance of the booking. */
  Fail = 'fail',
  /** Acknowledge all work for a booking is done and complete the booking. */
  Finalize = 'finalize',
  /** Begin sending any offboarding requirements and instructions. */
  Offboard = 'offboard',
  /** Begin the onboarding process for a booking. */
  Onboard = 'onboard',
  /** Project owner rejects the booking. */
  Reject = 'reject',
  /** Request additional terms be fulfilled by lesee before acceptance. */
  RequestTerms = 'request_terms'
}

/** The color scheme used for the project. */
export type ColorScheme = Entity & Node & {
  __typename?: 'ColorScheme';
  /** The hex value for the calendar text display */
  calendarTextColor: Scalars['String'];
  /** The time the lesee was added to the system. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The text to display for the header. */
  headerText: Scalars['String'];
  /** The link to view the full entity detail page. */
  href: Scalars['String'];
  /** The id of the project. */
  id: Scalars['ID'];
  /** The name of the color scheme. */
  name: Scalars['String'];
  /** The name of the query to use to retrieve the entity. */
  queryName: Scalars['String'];
  /** The time the lesee was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ComparisonDirectionTypes {
  /** Compare values going backward. For dates, this equates to the past. */
  Backward = 'backward',
  /** Compare values going forward. For dates, this equates to the future. */
  Forward = 'forward'
}

/** Autogenerated return type of CreateBooking. */
export type CreateBookingPayload = {
  __typename?: 'CreateBookingPayload';
  /** The created booking_reason */
  booking: Booking;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of CreateBookingReason. */
export type CreateBookingReasonPayload = {
  __typename?: 'CreateBookingReasonPayload';
  /** The created booking_reason */
  bookingReason: BookingReason;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of CreateLessee. */
export type CreateLesseePayload = {
  __typename?: 'CreateLesseePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The created lessee */
  lessee: Lessee;
};

/** Autogenerated return type of CreateProject. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The Created project */
  project: Project;
};

/** Autogenerated return type of CreateRevenue. */
export type CreateRevenuePayload = {
  __typename?: 'CreateRevenuePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The revenue created */
  revenue: Revenue;
};

/** The data type to define how a datetime filter input should behave. */
export type DatetimeOption = {
  __typename?: 'DatetimeOption';
  /** The default direction to search for. */
  defaultDirection: ComparisonDirectionTypes;
  /** The type of operator to compare the selected value against by default. */
  defaultOperator: OperatorTypes;
  /** The default relative value to use in the operator by default. */
  defaultRelativeValue: RelativeComparisonTypes;
  /** The type of value to display for datetime selection by default. */
  defaultValueType: ValueComparisonTypes;
};

export enum DisplayTypes {
  /** Display entity in a drawer. */
  Drawer = 'drawer',
  /** Display entity in a modal. */
  Modal = 'modal'
}

/** The data type to define what an entity type looks like. */
export type Entity = {
  /** The text to display for the header. */
  headerText: Scalars['String'];
  /** The link to view the full entity detail page. */
  href: Scalars['String'];
  /** The id of the entity. */
  id: Scalars['ID'];
  /** The name of the query to use to retrieve the entity. */
  queryName: Scalars['String'];
};

/** The data type to define how an entity attribute should behave in various contexts. */
export type EntityAttribute = {
  __typename?: 'EntityAttribute';
  /** The key to the value in the data structure used to house the entity object. */
  key: Scalars['String'];
  /** Whether the attribute can be used for sorting. */
  sortable: Scalars['Boolean'];
  /** The text to display of the entity. */
  text: Scalars['String'];
};

/** The data type to define how an equality filter input should behave. */
export type EqualitySearchInput = {
  /** Sets whether looking forward or backward. Needed if: datetime-relative */
  direction?: InputMaybe<ComparisonDirectionTypes>;
  /** The type of operator to use in the compare of the lookup. */
  operator: OperatorTypes;
  /** An untyped comparison value. No attempt will be made to parse this value (unless value_type is relative). */
  value: Scalars['String'];
  /** Sets whether relative or exact. If relative, assumes a relative comparison type value has been passed. */
  valueType?: InputMaybe<ValueComparisonTypes>;
};

export type Error = {
  __typename?: 'Error';
  /** ID of the input object, if it was array of inputs. */
  id?: Maybe<Scalars['ID']>;
  /** Error key, that can be mapped to mutation's input. */
  key?: Maybe<Scalars['String']>;
  /** Error messages, related to the input object. */
  messages?: Maybe<Array<Scalars['String']>>;
};

/** The data type to define how a filter should behave. */
export type Filter = {
  __typename?: 'Filter';
  /** The input type to use for the filter */
  inputType: FilterInputTypes;
  /** The text to display in the label. */
  labelText: Scalars['String'];
  /** The behavior to use for selectable input types. */
  options: InputOption;
  /** The variable to map the filter value to. */
  variable: Scalars['String'];
};

/** The data type to define how a table should the filters defined for the table. */
export type FilterBy = {
  __typename?: 'FilterBy';
  /** A set of filters to apply for a given configuration */
  filterSets: Array<FilterSet>;
  /** A set of filters to setup for the table. */
  filters: Array<Filter>;
};

export enum FilterInputTypes {
  /** Use an async autocomplete filter. */
  Async = 'async',
  /** Use an asyncautocomplete with multiple options. */
  AsyncMulti = 'async_multi',
  /** Use an autocomplete select. */
  Autocomplete = 'autocomplete',
  /** Use an autocomplete select with multiple options. */
  AutocompleteMulti = 'autocomplete_multi',
  /** Use an datetime select with operator select. */
  Datetime = 'datetime',
  /** Option is not defined, frontend must do something with it. Useful for things like sortBy. */
  Formless = 'formless',
  /** Use a search input. */
  Search = 'search',
  /** Use a regular singular select. */
  Select = 'select',
  /** Use a regular multi select. */
  SelectMulti = 'select_multi'
}

/** The data type to define how a filter should behave. */
export type FilterSet = {
  __typename?: 'FilterSet';
  /** The filters to use */
  filters: Array<FilterSetFilter>;
  /** The text to display in the label. */
  labelText: Scalars['String'];
};

/** The data type to define how a datetime filter input should behave. */
export type FilterSetFilter = {
  __typename?: 'FilterSetFilter';
  /** The input type to use for the data */
  inputType: FilterInputTypes;
  /** The data to use for the filter. */
  options?: Maybe<FilterSetOptions>;
  /** The variable to map the filter value to. */
  variable: Scalars['String'];
};

/** The data type to define how a filter set should behave. */
export type FilterSetOptions = {
  __typename?: 'FilterSetOptions';
  /** The direction to search for (only used if datetime). */
  direction?: Maybe<ComparisonDirectionTypes>;
  /** The type of operator to compare the selected value against by default. */
  operator?: Maybe<OperatorTypes>;
  /** The value to use for the filter. */
  value?: Maybe<RelativeComparisonTypes>;
  /** For multi inputs. String values to handle multiple types. */
  valueArray?: Maybe<Array<Scalars['String']>>;
  /** For single inputs. String value to handle multiple types. */
  valueSingle?: Maybe<Scalars['String']>;
  /** The type of value to display for datetime selection by default (only used if datetime). */
  valueType?: Maybe<ValueComparisonTypes>;
};

/** The data type to define how an option for an input should behave. */
export type InputOption = {
  __typename?: 'InputOption';
  /** If set, will use a defined async option type over the hardcoded option type. */
  async?: Maybe<AsyncOption>;
  /** If set, will use the defined datetime option type over the hardcoded option type. */
  datetime?: Maybe<DatetimeOption>;
  /** If set, will use the hardocoded option type. */
  options?: Maybe<Array<Option>>;
};

/**
 * The party that your booking is for. Specifically, the lesee could be a person, a
 * group of people, a company, or some other organization. A lesee is given a
 * specific type to help gather internal metrics on what type of party you are
 * booking with the most and where you have room to grow.
 */
export type Lessee = Node & {
  __typename?: 'Lessee';
  /** The time the lesee was added to the system. */
  createdAt: Scalars['ISO8601DateTime'];
  /** A description of the lesee used to provide context about a lesee as necessary. */
  description?: Maybe<Scalars['String']>;
  /** The email of the lessee */
  email?: Maybe<Scalars['String']>;
  /** The id of the lessee. */
  id: Scalars['ID'];
  /** The name of the lessee. */
  name: Scalars['String'];
  /** The phone number of the lessee */
  phone?: Maybe<Scalars['String']>;
  /** The time the lesee was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** LesseeSearchParams describes the search parameters that can be used to get lessees in the system. */
export type LesseeSearchParams = {
  /** Filter by the lessee phone number. */
  email?: InputMaybe<Scalars['String']>;
  /** If present will alter the lookup by applying the new search operator, otherwise uses ilike. */
  emailOperator?: InputMaybe<StringOperatorTypes>;
  /** Filter by the lessee id. */
  lesseeId?: InputMaybe<Scalars['ID']>;
  /** Filter by the lessee phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** If present will alter the lookup by applying the new search operator, otherwise uses ilike. */
  phoneOperator?: InputMaybe<StringOperatorTypes>;
  /** If present, will or the search values */
  searchWithOr?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the lessee title. */
  title?: InputMaybe<Scalars['String']>;
  /** If present will alter the lookup by applying the new search operator, otherwise uses ilike. */
  titleOperator?: InputMaybe<StringOperatorTypes>;
};

/** Autogenerated return type of LoginUser. */
export type LoginUserPayload = {
  __typename?: 'LoginUserPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  redirectPath?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Archives a project */
  archiveProject?: Maybe<ArchiveProjectPayload>;
  /** Assign a servicer to a booking assignment */
  assignBooking?: Maybe<AssignPayload>;
  /** Create a new booking. */
  createBooking?: Maybe<CreateBookingPayload>;
  /** Create a new booking reason. Returns the original reason if there is a duplicate. */
  createBookingReason?: Maybe<CreateBookingReasonPayload>;
  /** Create a new lessee. */
  createLessee?: Maybe<CreateLesseePayload>;
  /** Create an existing project, excluding the archived field. */
  createProject?: Maybe<CreateProjectPayload>;
  /** Creates a revenue for a booking */
  createRevenue?: Maybe<CreateRevenuePayload>;
  loginUser?: Maybe<LoginUserPayload>;
  /** Rate a lessee for a given booking */
  rateLessee?: Maybe<RateLesseePayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Transition a booking from one state to another. Failed transitions will return an error */
  transitionBooking?: Maybe<TransitionPayload>;
  /** Unarchives a project */
  unarchiveProject?: Maybe<UnarchiveProjectPayload>;
  /** Remove a servicer from a booking assignment */
  unassignBooking?: Maybe<UnassignPayload>;
  /** Update a booking. */
  updateBooking?: Maybe<UpdateBookingPayload>;
  /** Update an existing color scheme. */
  updateColorScheme?: Maybe<UpdateColorSchemePayload>;
  /** Update an existing project, excluding the archived field. */
  updateProject?: Maybe<UpdateProjectPayload>;
};


export type MutationArchiveProjectArgs = {
  archived: Scalars['Boolean'];
  projectId: Scalars['ID'];
};


export type MutationAssignBookingArgs = {
  assignmentId: Scalars['ID'];
  bookingId: Scalars['ID'];
  expectedCompletionDate?: InputMaybe<Scalars['Datetime']>;
  instructions?: InputMaybe<Scalars['String']>;
  servicerId: Scalars['ID'];
};


export type MutationCreateBookingArgs = {
  amount: Scalars['Float'];
  bookingDetails?: InputMaybe<BookingDetailsInput>;
  bookingOriginatorId: Scalars['ID'];
  bookingReason?: InputMaybe<Scalars['ID']>;
  endAt: Scalars['Datetime'];
  lesseeId: Scalars['ID'];
  projectId: Scalars['ID'];
  startAt: Scalars['Datetime'];
};


export type MutationCreateBookingReasonArgs = {
  title: Scalars['String'];
};


export type MutationCreateLesseeArgs = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  calendarTextColor: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<ProjectTypes>;
};


export type MutationCreateRevenueArgs = {
  amount: Scalars['Float'];
  bookingId: Scalars['ID'];
  payoutUrl?: InputMaybe<Scalars['String']>;
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  title: Scalars['String'];
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRateLesseeArgs = {
  bookingId: Scalars['ID'];
  rating: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
};


export type MutationTransitionBookingArgs = {
  bookingId: Scalars['ID'];
  transition: BookingTransitions;
};


export type MutationUnarchiveProjectArgs = {
  archived: Scalars['Boolean'];
  projectId: Scalars['ID'];
};


export type MutationUnassignBookingArgs = {
  bookingAssignmentId: Scalars['ID'];
};


export type MutationUpdateBookingArgs = {
  bookingDetails?: InputMaybe<BookingDetailsInput>;
  bookingOriginatorId?: InputMaybe<Scalars['ID']>;
  bookingReason?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['Datetime']>;
  id: Scalars['ID'];
  lesseeId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['Datetime']>;
};


export type MutationUpdateColorSchemeArgs = {
  calendarTextColor?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateProjectArgs = {
  colorSchemeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

/** The data type to define how a mutation action should behave. */
export type MutationAction = {
  __typename?: 'MutationAction';
  /** The key to lookup the mutation. */
  mutationKey: Scalars['String'];
  /** The variables to use in the mutation. */
  variables: Array<Scalars['String']>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

export enum OperatorTypes {
  /** Filter by values equal to value. */
  Eq = 'eq',
  /** Filter by values greater than value. */
  Gt = 'gt',
  /** Filter by values greater than or equal to value. */
  Gte = 'gte',
  /** Filter by values less than value. */
  Lt = 'lt',
  /** Filter by values less than value or equal to value. */
  Lte = 'lte'
}

/** The data type to define how an option should behave. */
export type Option = {
  __typename?: 'Option';
  /** The label to use for this option. */
  label: Scalars['String'];
  /** The value to use for this option. */
  value: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** The project for which bookings, revenues, expenses, and other data are attached to. */
export type Project = Node & {
  __typename?: 'Project';
  /** Whether or not the project is archived. */
  archived: Scalars['Boolean'];
  /** The color scheme to use for the project */
  colorScheme: ColorScheme;
  /** The time the lesee was added to the system. */
  createdAt: Scalars['ISO8601DateTime'];
  /** A description of the project used to provide context about what the purpose of the project is. */
  description?: Maybe<Scalars['String']>;
  /** The id of the project. */
  id: Scalars['ID'];
  /** The name of the project. */
  name: Scalars['String'];
  /** The type the project consists of */
  projectType: Scalars['String'];
  /** The time the lesee was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Takes in the arguments that will be used to search for projects that match. */
export type ProjectSearchParams = {
  /** Filter by the project id. */
  id?: InputMaybe<Scalars['ID']>;
  /** Filter by the name of the project. */
  name?: InputMaybe<StringSearchInput>;
  /** The key to sort by. Accepts dot notation for nested value sorts. */
  sortBy?: InputMaybe<Scalars['String']>;
  /** The name of the query to use to retrieve the entity. */
  sortDirection?: InputMaybe<SortDirectionTypes>;
};

export enum ProjectTypes {
  /** A short term rental project type */
  ShortTermRental = 'short_term_rental',
  /** A watercraft project type */
  Watercraft = 'watercraft'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve a list of the bookings the current user has been assigned to */
  assignedBookings: Array<AssignedBooking>;
  /** Retrieve a list of all assignable users for this booking assignment */
  availableServicers: Array<Servicer>;
  /** Retrieve a single booking by it's id */
  booking: Booking;
  /** Retrieve a list of assignments for a booking */
  bookingAssignments: Array<BookingAssignment>;
  /** Retrieve a list of all booking reasons */
  bookingReasons: Array<BookingReason>;
  /** Retrieve all the bookings relative to the search parameters passed in. */
  bookings: BookingConnection;
  /**
   * Retrieve all the bookings relative to the search parameters passed in within a
   * given range. Calculations to include bookings that don't start or end in this
   * range is also conducted to include all bookings occurring during the range.
   * Use this method to populate things like calendars and schedules.
   */
  bookingsByRange: BookingConnection;
  /** Retrieve the color scheme for a project by the project id */
  colorScheme: ColorScheme;
  /** Retrieve a single lessee by its id */
  lessee: Lessee;
  /** Retrieve a list of lessees from the system relative to the search params. */
  lessees: Array<Lessee>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Retrieve a single project by it's id */
  project: Project;
  /** An array of possible project types to use when creating a project */
  projectTypes: Array<ProjectTypes>;
  /** Retrieve all the projects relative to the search parameters passed in. */
  projects: Array<Project>;
  /** Retrieve a list of revenue sources for the system. */
  revenueSources: Array<BookingSource>;
  /** Retrieve a list of servicers from the system relative to the search params. */
  servicers: Array<Servicer>;
  /** The display values for the queried table to be generated on the front end. */
  tableDisplay: Table;
};


export type QueryAvailableServicersArgs = {
  bookingAssignmentId: Scalars['ID'];
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingAssignmentsArgs = {
  bookingId: Scalars['ID'];
};


export type QueryBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchParams?: InputMaybe<BookingSearchParams>;
};


export type QueryBookingsByRangeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchParams?: InputMaybe<BookingSearchParams>;
};


export type QueryColorSchemeArgs = {
  projectId: Scalars['ID'];
};


export type QueryLesseeArgs = {
  id: Scalars['ID'];
};


export type QueryLesseesArgs = {
  searchParams?: InputMaybe<LesseeSearchParams>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  searchParams?: InputMaybe<ProjectSearchParams>;
};


export type QueryServicersArgs = {
  searchParams?: InputMaybe<ServicerSearchParams>;
};


export type QueryTableDisplayArgs = {
  entity: Scalars['String'];
};

/** Autogenerated return type of RateLessee. */
export type RateLesseePayload = {
  __typename?: 'RateLesseePayload';
  /** The rated booking */
  booking: Booking;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export enum RelativeComparisonTypes {
  /** Relative value is 180 days from today. */
  HalfYear = 'half_year',
  /** Relative value is 30 days from today. */
  Month = 'month',
  /** Relative value is 90 days from today. */
  Quarter = 'quarter',
  /** Relative value is for today. */
  Today = 'today',
  /** Relative value is 7 days from today. */
  Week = 'week',
  /** Relative value is 365 days from today. */
  Year = 'year'
}

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<User>;
};

/** A description of revenue that has entered the system. */
export type Revenue = Node & {
  __typename?: 'Revenue';
  /** The amount of the revenue */
  amount: Scalars['Float'];
  /** The time the revenue was recorded. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The id of the revenue. */
  id: Scalars['ID'];
  /** The url to the payout */
  payoutUrl?: Maybe<Scalars['String']>;
  /** The time the revenue was received into your bank account. */
  receivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The short description of the revenue. */
  title: Scalars['String'];
  /** The time the revenue was last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A person, company, or organization that is used to for manage a part of the booking. */
export type Servicer = Node & {
  __typename?: 'Servicer';
  /** The id of the servicer */
  id: Scalars['ID'];
  /** The name of the servicer. */
  name: Scalars['String'];
};

/** ServicerSearchParams describes the search parameters that can be used to get servicers in the system. */
export type ServicerSearchParams = {
  /** Filter by the servicer id. */
  id?: InputMaybe<Scalars['ID']>;
  /** If present, will or the search values */
  searchWithOr?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the servicer name. */
  title?: InputMaybe<Scalars['String']>;
  /** If present will alter the lookup by applying the new search operator, otherwise uses ilike. */
  titleOperator?: InputMaybe<StringOperatorTypes>;
};

/** A short term rental type, such as an Airbnb or VRBO listing. */
export type ShortTermRental = BookingDetailsInterface & {
  __typename?: 'ShortTermRental';
  /** Instructions specific for this booking specific to the lessee. */
  additionalLesseeInstructions?: Maybe<Scalars['String']>;
  /** The time the details were last created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The door code to gain access, if applicable */
  doorCode?: Maybe<Scalars['String']>;
  /** The id of the booking details */
  id: Scalars['ID'];
  /** Instructions specific for this booking. */
  lesseeInstructions?: Maybe<Scalars['String']>;
  /** The time the details were last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SortDirectionTypes {
  /** Sort in ascending order. */
  Asc = 'asc',
  /** Sort in descending order. */
  Desc = 'desc'
}

export enum StringOperatorTypes {
  /** Filter by values that equals the exact string. */
  Exact = 'exact',
  /** Filter by values that contain the string case insensitive. */
  Ilike = 'ilike',
  /** Filter by values that contain the string case sensitive. */
  Like = 'like'
}

/** The data type to define how an equality filter input should behave. */
export type StringSearchInput = {
  /** The type of operator to use in the compare of the lookup. */
  operator?: InputMaybe<StringOperatorTypes>;
  /** An untyped comparison value. No attempt will be made to parse this value (unless value_type is relative). */
  value: Scalars['String'];
};

/** The data type to define how a table should look and behave. Table cell values are mapped to the header value's key. */
export type Table = {
  __typename?: 'Table';
  /** A set of filters to setup for the table. */
  filterBy: FilterBy;
  /** The headers of the table. */
  headers: Array<EntityAttribute>;
  /** The label text for the table. */
  labelText: Scalars['String'];
  /**
   * The rows of the table. If blank, will use the headers values to determine the order of the row \
   *    by using the key value defined in the table header type.
   */
  rows: TableRow;
};

/** The data type to define how a table cell should behave. */
export type TableCell = {
  __typename?: 'TableCell';
  /**
   * If present, will make the text content display a link that, when clicked, will open a\
   *      display with the associated entity's display information.
   */
  associatedEntity?: Maybe<AssociatedEntity>;
  /**
   * A custom component to use for the display. Will pass the data retrieved by the key as a prop\
   *      called data
   */
  component?: Maybe<Scalars['String']>;
  /** If present, will make the text content a link and sets the href to this value. */
  href?: Maybe<Scalars['String']>;
  /**
   * The key to the value in the json object to display. Use dot notation to perform nested key lookup.\
   *      ie. project.name. Use ! if you want to skip lookup and have the component used to render have access to all the \
   *      data of the returned object
   */
  key: Scalars['String'];
};

/** The data type to define how a table row should behave. */
export type TableRow = {
  __typename?: 'TableRow';
  /** The actions to take on the row. Should render nothing if null. */
  actions: Array<TableRowAction>;
  /** The cells of the row. Display order is determined by the order of the cells defined in the header of the table. */
  cells: Array<TableCell>;
  /** Whether to make the row clickable. */
  rowClickable: Scalars['Boolean'];
};

/** The data type to define how a table row action should behave. */
export type TableRowAction = {
  __typename?: 'TableRowAction';
  /**
   * The name of the action to take. If this requires a mutation, \
   *           then add a value for the mutation key.
   */
  action?: Maybe<Scalars['String']>;
  /** The mutation action to take. */
  mutation?: Maybe<MutationAction>;
  /** The text to display for the action */
  text: Scalars['String'];
};

/** Autogenerated return type of Transition. */
export type TransitionPayload = {
  __typename?: 'TransitionPayload';
  /** The transitioned booking */
  booking: Booking;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of UnarchiveProject. */
export type UnarchiveProjectPayload = {
  __typename?: 'UnarchiveProjectPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The archived project */
  project: Project;
};

/** Autogenerated return type of Unassign. */
export type UnassignPayload = {
  __typename?: 'UnassignPayload';
  /** The removed assignment */
  bookingAssignment: BookingAssignment;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of UpdateBooking. */
export type UpdateBookingPayload = {
  __typename?: 'UpdateBookingPayload';
  /** The created booking_reason */
  booking: Booking;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of UpdateColorScheme. */
export type UpdateColorSchemePayload = {
  __typename?: 'UpdateColorSchemePayload';
  /** The updated color scheme */
  colorScheme: ColorScheme;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Autogenerated return type of UpdateProject. */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  /** The updated project */
  project: Project;
};

/** The authenticated user. */
export type User = Node & {
  __typename?: 'User';
  /** The email of the user */
  email: Scalars['String'];
  /** The id of the user */
  id: Scalars['ID'];
  /** The name of the user */
  name: Scalars['String'];
};

export enum ValueComparisonTypes {
  /** Use exact comparison (ie. 2023-03-03, 2023-03-03T12:12:12:01Z). */
  Exact = 'exact',
  /** Use relative comparison (ie. week, month, quarter, year). */
  Relative = 'relative'
}

/** A watercraft booking, such as jet skis or a boat */
export type Watercraft = BookingDetailsInterface & {
  __typename?: 'Watercraft';
  /** Instructions specific for this booking specific to the lessee. */
  additionalLesseeInstructions?: Maybe<Scalars['String']>;
  /** The time the details were last created. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The desitination the watercraft will be put in. */
  destination: Scalars['String'];
  /** The id of the booking details */
  id: Scalars['ID'];
  /** Instructions specific for this booking. */
  lesseeInstructions?: Maybe<Scalars['String']>;
  /** Has the lesee viewed the safety instructions? */
  safetyInstructionsViewed: Scalars['Boolean'];
  /** The time the details were last updated. */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Has the lesee signed the waiver? */
  waiverSigned: Scalars['Boolean'];
  /** Instructions specific for the operation of this watercraft. */
  watercraftInstructions?: Maybe<Scalars['String']>;
};

export type AssigneeFragmentFragment = { __typename?: 'Servicer', id: string, name: string };

export type AssignmentFragmentFragment = { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any };

export type BookingAssignmentFragmentFragment = { __typename?: 'BookingAssignment', id: string, additionalInstructions?: string | null, expectedCompletionDate: any, assignedAt?: any | null, acceptedAt?: any | null, updatedAt: any, assignee?: { __typename?: 'Servicer', id: string, name: string } | null, assignment: { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any } };

export type BookingFragmentFragment = { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } };

export type BookingWithDetailsFragmentFragment = { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingDetails: { __typename?: 'ShortTermRental', doorCode?: string | null, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null } | { __typename?: 'Watercraft', watercraftInstructions?: string | null, waiverSigned: boolean, safetyInstructionsViewed: boolean, destination: string, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } };

export type ColorSchemeFragmentFragment = { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string };

export type ErrorFragmentFragment = { __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null };

export type FullProjectFragmentFragment = { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } };

export type LesseeFragmentFragment = { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any };

export type ProjectFragmentFragment = { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean };

export type RevenueFragmentFragment = { __typename?: 'Revenue', id: string, title: string, amount: number, receivedAt?: any | null, payoutUrl?: string | null };

export type RevenueSourceFragmentFragment = { __typename?: 'BookingSource', id: string, description?: string | null, name: string };

export type ServicerFragmentFragment = { __typename?: 'Servicer', id: string, name: string };

export type ArchiveProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  archived: Scalars['Boolean'];
}>;


export type ArchiveProjectMutation = { __typename?: 'Mutation', archiveProject?: { __typename?: 'ArchiveProjectPayload', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type AssignBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  servicerId: Scalars['ID'];
  assignmentId: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
}>;


export type AssignBookingMutation = { __typename?: 'Mutation', assignBooking?: { __typename?: 'AssignPayload', bookingAssignment: { __typename?: 'BookingAssignment', id: string, additionalInstructions?: string | null, expectedCompletionDate: any, assignedAt?: any | null, acceptedAt?: any | null, updatedAt: any, assignee?: { __typename?: 'Servicer', id: string, name: string } | null, assignment: { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type CreateBookingMutationVariables = Exact<{
  projectId: Scalars['ID'];
  bookingOriginatorId: Scalars['ID'];
  lesseeId: Scalars['ID'];
  startAt: Scalars['Datetime'];
  endAt: Scalars['Datetime'];
  amount: Scalars['Float'];
  bookingDetails?: InputMaybe<BookingDetailsInput>;
  bookingReason?: InputMaybe<Scalars['ID']>;
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking?: { __typename?: 'CreateBookingPayload', errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null, booking: { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingDetails: { __typename?: 'ShortTermRental', doorCode?: string | null, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null } | { __typename?: 'Watercraft', watercraftInstructions?: string | null, waiverSigned: boolean, safetyInstructionsViewed: boolean, destination: string, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } } } | null };

export type CreateBookingReasonMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateBookingReasonMutation = { __typename?: 'Mutation', createBookingReason?: { __typename?: 'CreateBookingReasonPayload', errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null, bookingReason: { __typename?: 'BookingReason', id: string, title: string } } | null };

export type CreateLesseeMutationVariables = Exact<{
  title: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateLesseeMutation = { __typename?: 'Mutation', createLessee?: { __typename?: 'CreateLesseePayload', errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null, lessee: { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any } } | null };

export type CreateProjectMutationVariables = Exact<{
  title: Scalars['String'];
  type: ProjectTypes;
  calendarTextColor: Scalars['String'];
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject?: { __typename?: 'CreateProjectPayload', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type CreateRevenueMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  title: Scalars['String'];
  amount: Scalars['Float'];
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  payoutUrl?: InputMaybe<Scalars['String']>;
}>;


export type CreateRevenueMutation = { __typename?: 'Mutation', createRevenue?: { __typename?: 'CreateRevenuePayload', errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null, revenue: { __typename?: 'Revenue', id: string, title: string, amount: number, receivedAt?: any | null, payoutUrl?: string | null } } | null };

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'LoginUserPayload', redirectPath?: string | null, user?: { __typename?: 'User', id: string, email: string } | null, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type RateLesseeMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  rating: Scalars['Int'];
}>;


export type RateLesseeMutation = { __typename?: 'Mutation', rateLessee?: { __typename?: 'RateLesseePayload', booking: { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordPayload', user?: { __typename?: 'User', id: string, email: string } | null, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type TransitionBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  transition: BookingTransitions;
}>;


export type TransitionBookingMutation = { __typename?: 'Mutation', transitionBooking?: { __typename?: 'TransitionPayload', booking: { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingDetails: { __typename?: 'ShortTermRental', doorCode?: string | null, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null } | { __typename?: 'Watercraft', watercraftInstructions?: string | null, waiverSigned: boolean, safetyInstructionsViewed: boolean, destination: string, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type UnarchiveProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  archived: Scalars['Boolean'];
}>;


export type UnarchiveProjectMutation = { __typename?: 'Mutation', unarchiveProject?: { __typename?: 'UnarchiveProjectPayload', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type UnassignBookingMutationVariables = Exact<{
  bookingAssignmentId: Scalars['ID'];
}>;


export type UnassignBookingMutation = { __typename?: 'Mutation', unassignBooking?: { __typename?: 'UnassignPayload', bookingAssignment: { __typename?: 'BookingAssignment', id: string, additionalInstructions?: string | null, expectedCompletionDate: any, assignedAt?: any | null, acceptedAt?: any | null, updatedAt: any, assignee?: { __typename?: 'Servicer', id: string, name: string } | null, assignment: { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any } }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type UpdateBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  projectId?: InputMaybe<Scalars['ID']>;
  bookingOriginatorId?: InputMaybe<Scalars['ID']>;
  lesseeId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['Datetime']>;
  endAt?: InputMaybe<Scalars['Datetime']>;
  bookingDetails?: InputMaybe<BookingDetailsInput>;
  bookingReason?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateBookingMutation = { __typename?: 'Mutation', updateBooking?: { __typename?: 'UpdateBookingPayload', errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null, booking: { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, bookingDetails: { __typename?: 'ShortTermRental', doorCode?: string | null, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null } | { __typename?: 'Watercraft', watercraftInstructions?: string | null, waiverSigned: boolean, safetyInstructionsViewed: boolean, destination: string, lesseeInstructions?: string | null, additionalLesseeInstructions?: string | null }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } } } | null };

export type UpdateColorSchemeMutationVariables = Exact<{
  id: Scalars['ID'];
  calendarTextColor?: InputMaybe<Scalars['String']>;
}>;


export type UpdateColorSchemeMutation = { __typename?: 'Mutation', updateColorScheme?: { __typename?: 'UpdateColorSchemePayload', colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject?: { __typename?: 'UpdateProjectPayload', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean }, errors?: Array<{ __typename?: 'Error', id?: string | null, key?: string | null, messages?: Array<string> | null } | null> | null } | null };

export type AssignedBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type AssignedBookingsQuery = { __typename?: 'Query', assignedBookings: Array<{ __typename?: 'AssignedBooking', id: string, serviceDate?: any | null, location: string, name: string, acceptedAt?: any | null, instructions?: string | null, addToCalendar: Array<string> }> };

export type AvailableServicersQueryVariables = Exact<{
  bookingAssignmentId: Scalars['ID'];
}>;


export type AvailableServicersQuery = { __typename?: 'Query', availableServicers: Array<{ __typename?: 'Servicer', id: string, name: string }> };

export type BookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BookingQuery = { __typename?: 'Query', booking: { __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean }, lessee: { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any }, revenues: Array<{ __typename?: 'Revenue', id: string, title: string, amount: number, receivedAt?: any | null, payoutUrl?: string | null }>, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } } };

export type BookingAssignmentsQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type BookingAssignmentsQuery = { __typename?: 'Query', bookingAssignments: Array<{ __typename?: 'BookingAssignment', id: string, additionalInstructions?: string | null, expectedCompletionDate: any, assignedAt?: any | null, acceptedAt?: any | null, updatedAt: any, assignee?: { __typename?: 'Servicer', id: string, name: string } | null, assignment: { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any } }> };

export type BookingReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingReasonsQuery = { __typename?: 'Query', bookingReasons: Array<{ __typename?: 'BookingReason', id: string, title: string }> };

export type BookingsQueryVariables = Exact<{
  searchParams?: InputMaybe<BookingSearchParams>;
}>;


export type BookingsQuery = { __typename?: 'Query', bookings: { __typename?: 'BookingConnection', nodes?: Array<{ __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, assignments: Array<{ __typename?: 'BookingAssignment', id: string, additionalInstructions?: string | null, expectedCompletionDate: any, assignedAt?: any | null, acceptedAt?: any | null, updatedAt: any, assignee?: { __typename?: 'Servicer', id: string, name: string } | null, assignment: { __typename?: 'Assignment', id: string, name?: string | null, instructions?: string | null, createdAt: any, updatedAt: any } }>, project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean }, lessee: { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } } | null> | null } };

export type BookingsByRangeQueryVariables = Exact<{
  searchParams?: InputMaybe<BookingSearchParams>;
}>;


export type BookingsByRangeQuery = { __typename?: 'Query', bookingsByRange: { __typename?: 'BookingConnection', nodes?: Array<{ __typename?: 'Booking', id: string, startAt: any, endAt: any, validTransitions: Array<BookingTransitions>, status: BookingStatuses, lesseeRating?: number | null, project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } }, lessee: { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any }, bookingReason: { __typename?: 'BookingReason', id: string, title: string }, bookingOriginator: { __typename?: 'BookingSource', id: string, name: string } } | null> | null } };

export type LesseeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LesseeQuery = { __typename?: 'Query', lessee: { __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any } };

export type LesseesQueryVariables = Exact<{
  searchParams?: InputMaybe<LesseeSearchParams>;
}>;


export type LesseesQuery = { __typename?: 'Query', lessees: Array<{ __typename?: 'Lessee', id: string, name: string, description?: string | null, phone?: string | null, email?: string | null, createdAt: any, updatedAt: any }> };

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } } };

export type ProjectFullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectFullQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } } };

export type ProjectTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectTypesQuery = { __typename?: 'Query', projectTypes: Array<ProjectTypes> };

export type ProjectsQueryVariables = Exact<{
  searchParams?: InputMaybe<ProjectSearchParams>;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, projectType: string, archived: boolean, colorScheme: { __typename?: 'ColorScheme', id: string, name: string, calendarTextColor: string } }> };

export type RevenueSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type RevenueSourcesQuery = { __typename?: 'Query', revenueSources: Array<{ __typename?: 'BookingSource', id: string, description?: string | null, name: string }> };

export type ServicersQueryVariables = Exact<{
  searchParams?: InputMaybe<ServicerSearchParams>;
}>;


export type ServicersQuery = { __typename?: 'Query', servicers: Array<{ __typename?: 'Servicer', id: string, name: string }> };

export type TableDisplayQueryVariables = Exact<{
  entity: Scalars['String'];
}>;


export type TableDisplayQuery = { __typename?: 'Query', tableDisplay: { __typename?: 'Table', labelText: string, headers: Array<{ __typename?: 'EntityAttribute', text: string, sortable: boolean, key: string }>, filterBy: { __typename?: 'FilterBy', filters: Array<{ __typename?: 'Filter', inputType: FilterInputTypes, labelText: string, variable: string, options: { __typename?: 'InputOption', async?: { __typename?: 'AsyncOption', filter: string, query: string } | null, options?: Array<{ __typename?: 'Option', label: string, value: string }> | null, datetime?: { __typename?: 'DatetimeOption', defaultRelativeValue: RelativeComparisonTypes, defaultOperator: OperatorTypes, defaultDirection: ComparisonDirectionTypes } | null } }>, filterSets: Array<{ __typename?: 'FilterSet', labelText: string, filters: Array<{ __typename?: 'FilterSetFilter', inputType: FilterInputTypes, variable: string, options?: { __typename?: 'FilterSetOptions', valueType?: ValueComparisonTypes | null, operator?: OperatorTypes | null, value?: RelativeComparisonTypes | null, direction?: ComparisonDirectionTypes | null, valueArray?: Array<string> | null, valueSingle?: string | null } | null }> }> }, rows: { __typename?: 'TableRow', rowClickable: boolean, cells: Array<{ __typename?: 'TableCell', key: string, component?: string | null, href?: string | null, associatedEntity?: { __typename?: 'AssociatedEntity', displayType: DisplayTypes, entity: { __typename?: 'ColorScheme', headerText: string, href: string, id: string, queryName: string } } | null }>, actions: Array<{ __typename?: 'TableRowAction', action?: string | null, text: string, mutation?: { __typename?: 'MutationAction', mutationKey: string, variables: Array<string> } | null }> } } };

export const AssigneeFragmentFragmentDoc = gql`
    fragment AssigneeFragment on Servicer {
  id
  name
}
    `;
export const AssignmentFragmentFragmentDoc = gql`
    fragment AssignmentFragment on Assignment {
  id
  name
  instructions
  createdAt
  updatedAt
}
    `;
export const BookingAssignmentFragmentFragmentDoc = gql`
    fragment BookingAssignmentFragment on BookingAssignment {
  id
  additionalInstructions
  assignee {
    ...AssigneeFragment
  }
  assignment {
    ...AssignmentFragment
  }
  expectedCompletionDate
  assignedAt
  acceptedAt
  updatedAt
}
    ${AssigneeFragmentFragmentDoc}
${AssignmentFragmentFragmentDoc}`;
export const BookingFragmentFragmentDoc = gql`
    fragment BookingFragment on Booking {
  id
  startAt
  endAt
  bookingReason {
    id
    title
  }
  bookingOriginator {
    id
    name
  }
  validTransitions
  status
  lesseeRating
}
    `;
export const BookingWithDetailsFragmentFragmentDoc = gql`
    fragment BookingWithDetailsFragment on Booking {
  ...BookingFragment
  bookingDetails {
    lesseeInstructions
    additionalLesseeInstructions
    ... on ShortTermRental {
      doorCode
    }
    ... on Watercraft {
      watercraftInstructions
      waiverSigned
      safetyInstructionsViewed
      destination
    }
  }
}
    ${BookingFragmentFragmentDoc}`;
export const ErrorFragmentFragmentDoc = gql`
    fragment ErrorFragment on Error {
  id
  key
  messages
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  createdAt
  updatedAt
  projectType
  archived
}
    `;
export const ColorSchemeFragmentFragmentDoc = gql`
    fragment ColorSchemeFragment on ColorScheme {
  id
  name
  calendarTextColor
}
    `;
export const FullProjectFragmentFragmentDoc = gql`
    fragment FullProjectFragment on Project {
  ...ProjectFragment
  colorScheme {
    ...ColorSchemeFragment
  }
}
    ${ProjectFragmentFragmentDoc}
${ColorSchemeFragmentFragmentDoc}`;
export const LesseeFragmentFragmentDoc = gql`
    fragment LesseeFragment on Lessee {
  id
  name
  description
  phone
  email
  createdAt
  updatedAt
}
    `;
export const RevenueFragmentFragmentDoc = gql`
    fragment RevenueFragment on Revenue {
  id
  title
  amount
  receivedAt
  payoutUrl
}
    `;
export const RevenueSourceFragmentFragmentDoc = gql`
    fragment RevenueSourceFragment on BookingSource {
  id
  description
  name
}
    `;
export const ServicerFragmentFragmentDoc = gql`
    fragment ServicerFragment on Servicer {
  id
  name
}
    `;
export const ArchiveProjectDocument = gql`
    mutation ArchiveProject($projectId: ID!, $archived: Boolean!) {
  archiveProject(projectId: $projectId, archived: $archived) {
    project {
      ...ProjectFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<ArchiveProjectMutation, ArchiveProjectMutationVariables>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(ArchiveProjectDocument, options);
      }
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>;
export const AssignBookingDocument = gql`
    mutation AssignBooking($bookingId: ID!, $servicerId: ID!, $assignmentId: ID!, $instructions: String) {
  assignBooking(
    bookingId: $bookingId
    servicerId: $servicerId
    assignmentId: $assignmentId
    instructions: $instructions
  ) {
    bookingAssignment {
      ...BookingAssignmentFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${BookingAssignmentFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type AssignBookingMutationFn = Apollo.MutationFunction<AssignBookingMutation, AssignBookingMutationVariables>;

/**
 * __useAssignBookingMutation__
 *
 * To run a mutation, you first call `useAssignBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBookingMutation, { data, loading, error }] = useAssignBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      servicerId: // value for 'servicerId'
 *      assignmentId: // value for 'assignmentId'
 *      instructions: // value for 'instructions'
 *   },
 * });
 */
export function useAssignBookingMutation(baseOptions?: Apollo.MutationHookOptions<AssignBookingMutation, AssignBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignBookingMutation, AssignBookingMutationVariables>(AssignBookingDocument, options);
      }
export type AssignBookingMutationHookResult = ReturnType<typeof useAssignBookingMutation>;
export type AssignBookingMutationResult = Apollo.MutationResult<AssignBookingMutation>;
export type AssignBookingMutationOptions = Apollo.BaseMutationOptions<AssignBookingMutation, AssignBookingMutationVariables>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($projectId: ID!, $bookingOriginatorId: ID!, $lesseeId: ID!, $startAt: Datetime!, $endAt: Datetime!, $amount: Float!, $bookingDetails: BookingDetailsInput, $bookingReason: ID) {
  createBooking(
    projectId: $projectId
    bookingOriginatorId: $bookingOriginatorId
    lesseeId: $lesseeId
    startAt: $startAt
    endAt: $endAt
    amount: $amount
    bookingDetails: $bookingDetails
    bookingReason: $bookingReason
  ) {
    errors {
      ...ErrorFragment
    }
    booking {
      ...BookingWithDetailsFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}
${BookingWithDetailsFragmentFragmentDoc}`;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      bookingOriginatorId: // value for 'bookingOriginatorId'
 *      lesseeId: // value for 'lesseeId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      amount: // value for 'amount'
 *      bookingDetails: // value for 'bookingDetails'
 *      bookingReason: // value for 'bookingReason'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const CreateBookingReasonDocument = gql`
    mutation CreateBookingReason($title: String!) {
  createBookingReason(title: $title) {
    errors {
      ...ErrorFragment
    }
    bookingReason {
      id
      title
    }
  }
}
    ${ErrorFragmentFragmentDoc}`;
export type CreateBookingReasonMutationFn = Apollo.MutationFunction<CreateBookingReasonMutation, CreateBookingReasonMutationVariables>;

/**
 * __useCreateBookingReasonMutation__
 *
 * To run a mutation, you first call `useCreateBookingReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingReasonMutation, { data, loading, error }] = useCreateBookingReasonMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateBookingReasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingReasonMutation, CreateBookingReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingReasonMutation, CreateBookingReasonMutationVariables>(CreateBookingReasonDocument, options);
      }
export type CreateBookingReasonMutationHookResult = ReturnType<typeof useCreateBookingReasonMutation>;
export type CreateBookingReasonMutationResult = Apollo.MutationResult<CreateBookingReasonMutation>;
export type CreateBookingReasonMutationOptions = Apollo.BaseMutationOptions<CreateBookingReasonMutation, CreateBookingReasonMutationVariables>;
export const CreateLesseeDocument = gql`
    mutation CreateLessee($title: String!, $email: String!, $phone: String!, $description: String!) {
  createLessee(
    title: $title
    email: $email
    phone: $phone
    description: $description
  ) {
    errors {
      ...ErrorFragment
    }
    lessee {
      ...LesseeFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}
${LesseeFragmentFragmentDoc}`;
export type CreateLesseeMutationFn = Apollo.MutationFunction<CreateLesseeMutation, CreateLesseeMutationVariables>;

/**
 * __useCreateLesseeMutation__
 *
 * To run a mutation, you first call `useCreateLesseeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLesseeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLesseeMutation, { data, loading, error }] = useCreateLesseeMutation({
 *   variables: {
 *      title: // value for 'title'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateLesseeMutation(baseOptions?: Apollo.MutationHookOptions<CreateLesseeMutation, CreateLesseeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLesseeMutation, CreateLesseeMutationVariables>(CreateLesseeDocument, options);
      }
export type CreateLesseeMutationHookResult = ReturnType<typeof useCreateLesseeMutation>;
export type CreateLesseeMutationResult = Apollo.MutationResult<CreateLesseeMutation>;
export type CreateLesseeMutationOptions = Apollo.BaseMutationOptions<CreateLesseeMutation, CreateLesseeMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($title: String!, $type: ProjectTypes!, $calendarTextColor: String!) {
  createProject(title: $title, type: $type, calendarTextColor: $calendarTextColor) {
    project {
      ...FullProjectFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${FullProjectFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      calendarTextColor: // value for 'calendarTextColor'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateRevenueDocument = gql`
    mutation CreateRevenue($bookingId: ID!, $title: String!, $amount: Float!, $receivedAt: Datetime, $payoutUrl: String) {
  createRevenue(
    bookingId: $bookingId
    title: $title
    amount: $amount
    receivedAt: $receivedAt
    payoutUrl: $payoutUrl
  ) {
    errors {
      ...ErrorFragment
    }
    revenue {
      ...RevenueFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}
${RevenueFragmentFragmentDoc}`;
export type CreateRevenueMutationFn = Apollo.MutationFunction<CreateRevenueMutation, CreateRevenueMutationVariables>;

/**
 * __useCreateRevenueMutation__
 *
 * To run a mutation, you first call `useCreateRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevenueMutation, { data, loading, error }] = useCreateRevenueMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      title: // value for 'title'
 *      amount: // value for 'amount'
 *      receivedAt: // value for 'receivedAt'
 *      payoutUrl: // value for 'payoutUrl'
 *   },
 * });
 */
export function useCreateRevenueMutation(baseOptions?: Apollo.MutationHookOptions<CreateRevenueMutation, CreateRevenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRevenueMutation, CreateRevenueMutationVariables>(CreateRevenueDocument, options);
      }
export type CreateRevenueMutationHookResult = ReturnType<typeof useCreateRevenueMutation>;
export type CreateRevenueMutationResult = Apollo.MutationResult<CreateRevenueMutation>;
export type CreateRevenueMutationOptions = Apollo.BaseMutationOptions<CreateRevenueMutation, CreateRevenueMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!, $rememberMe: Boolean) {
  loginUser(email: $email, password: $password, rememberMe: $rememberMe) {
    user {
      id
      email
    }
    redirectPath
    errors {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      rememberMe: // value for 'rememberMe'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const RateLesseeDocument = gql`
    mutation RateLessee($bookingId: ID!, $rating: Int!) {
  rateLessee(bookingId: $bookingId, rating: $rating) {
    booking {
      ...BookingFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${BookingFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type RateLesseeMutationFn = Apollo.MutationFunction<RateLesseeMutation, RateLesseeMutationVariables>;

/**
 * __useRateLesseeMutation__
 *
 * To run a mutation, you first call `useRateLesseeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateLesseeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateLesseeMutation, { data, loading, error }] = useRateLesseeMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useRateLesseeMutation(baseOptions?: Apollo.MutationHookOptions<RateLesseeMutation, RateLesseeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RateLesseeMutation, RateLesseeMutationVariables>(RateLesseeDocument, options);
      }
export type RateLesseeMutationHookResult = ReturnType<typeof useRateLesseeMutation>;
export type RateLesseeMutationResult = Apollo.MutationResult<RateLesseeMutation>;
export type RateLesseeMutationOptions = Apollo.BaseMutationOptions<RateLesseeMutation, RateLesseeMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($currentPassword: String!, $password: String!, $passwordConfirm: String!) {
  resetPassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirm: $passwordConfirm
  ) {
    user {
      id
      email
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      password: // value for 'password'
 *      passwordConfirm: // value for 'passwordConfirm'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const TransitionBookingDocument = gql`
    mutation TransitionBooking($bookingId: ID!, $transition: BookingTransitions!) {
  transitionBooking(bookingId: $bookingId, transition: $transition) {
    booking {
      ...BookingWithDetailsFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${BookingWithDetailsFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type TransitionBookingMutationFn = Apollo.MutationFunction<TransitionBookingMutation, TransitionBookingMutationVariables>;

/**
 * __useTransitionBookingMutation__
 *
 * To run a mutation, you first call `useTransitionBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionBookingMutation, { data, loading, error }] = useTransitionBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      transition: // value for 'transition'
 *   },
 * });
 */
export function useTransitionBookingMutation(baseOptions?: Apollo.MutationHookOptions<TransitionBookingMutation, TransitionBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransitionBookingMutation, TransitionBookingMutationVariables>(TransitionBookingDocument, options);
      }
export type TransitionBookingMutationHookResult = ReturnType<typeof useTransitionBookingMutation>;
export type TransitionBookingMutationResult = Apollo.MutationResult<TransitionBookingMutation>;
export type TransitionBookingMutationOptions = Apollo.BaseMutationOptions<TransitionBookingMutation, TransitionBookingMutationVariables>;
export const UnarchiveProjectDocument = gql`
    mutation UnarchiveProject($projectId: ID!, $archived: Boolean!) {
  unarchiveProject(projectId: $projectId, archived: $archived) {
    project {
      ...FullProjectFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${FullProjectFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type UnarchiveProjectMutationFn = Apollo.MutationFunction<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;

/**
 * __useUnarchiveProjectMutation__
 *
 * To run a mutation, you first call `useUnarchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProjectMutation, { data, loading, error }] = useUnarchiveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useUnarchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>(UnarchiveProjectDocument, options);
      }
export type UnarchiveProjectMutationHookResult = ReturnType<typeof useUnarchiveProjectMutation>;
export type UnarchiveProjectMutationResult = Apollo.MutationResult<UnarchiveProjectMutation>;
export type UnarchiveProjectMutationOptions = Apollo.BaseMutationOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;
export const UnassignBookingDocument = gql`
    mutation UnassignBooking($bookingAssignmentId: ID!) {
  unassignBooking(bookingAssignmentId: $bookingAssignmentId) {
    bookingAssignment {
      ...BookingAssignmentFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${BookingAssignmentFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type UnassignBookingMutationFn = Apollo.MutationFunction<UnassignBookingMutation, UnassignBookingMutationVariables>;

/**
 * __useUnassignBookingMutation__
 *
 * To run a mutation, you first call `useUnassignBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignBookingMutation, { data, loading, error }] = useUnassignBookingMutation({
 *   variables: {
 *      bookingAssignmentId: // value for 'bookingAssignmentId'
 *   },
 * });
 */
export function useUnassignBookingMutation(baseOptions?: Apollo.MutationHookOptions<UnassignBookingMutation, UnassignBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignBookingMutation, UnassignBookingMutationVariables>(UnassignBookingDocument, options);
      }
export type UnassignBookingMutationHookResult = ReturnType<typeof useUnassignBookingMutation>;
export type UnassignBookingMutationResult = Apollo.MutationResult<UnassignBookingMutation>;
export type UnassignBookingMutationOptions = Apollo.BaseMutationOptions<UnassignBookingMutation, UnassignBookingMutationVariables>;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($id: ID!, $projectId: ID, $bookingOriginatorId: ID, $lesseeId: ID, $startAt: Datetime, $endAt: Datetime, $bookingDetails: BookingDetailsInput, $bookingReason: ID) {
  updateBooking(
    id: $id
    projectId: $projectId
    bookingOriginatorId: $bookingOriginatorId
    lesseeId: $lesseeId
    startAt: $startAt
    endAt: $endAt
    bookingDetails: $bookingDetails
    bookingReason: $bookingReason
  ) {
    errors {
      ...ErrorFragment
    }
    booking {
      ...BookingWithDetailsFragment
    }
  }
}
    ${ErrorFragmentFragmentDoc}
${BookingWithDetailsFragmentFragmentDoc}`;
export type UpdateBookingMutationFn = Apollo.MutationFunction<UpdateBookingMutation, UpdateBookingMutationVariables>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      bookingOriginatorId: // value for 'bookingOriginatorId'
 *      lesseeId: // value for 'lesseeId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      bookingDetails: // value for 'bookingDetails'
 *      bookingReason: // value for 'bookingReason'
 *   },
 * });
 */
export function useUpdateBookingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingMutation, UpdateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(UpdateBookingDocument, options);
      }
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationResult = Apollo.MutationResult<UpdateBookingMutation>;
export type UpdateBookingMutationOptions = Apollo.BaseMutationOptions<UpdateBookingMutation, UpdateBookingMutationVariables>;
export const UpdateColorSchemeDocument = gql`
    mutation UpdateColorScheme($id: ID!, $calendarTextColor: String) {
  updateColorScheme(id: $id, calendarTextColor: $calendarTextColor) {
    colorScheme {
      ...ColorSchemeFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${ColorSchemeFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type UpdateColorSchemeMutationFn = Apollo.MutationFunction<UpdateColorSchemeMutation, UpdateColorSchemeMutationVariables>;

/**
 * __useUpdateColorSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateColorSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColorSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColorSchemeMutation, { data, loading, error }] = useUpdateColorSchemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      calendarTextColor: // value for 'calendarTextColor'
 *   },
 * });
 */
export function useUpdateColorSchemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateColorSchemeMutation, UpdateColorSchemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateColorSchemeMutation, UpdateColorSchemeMutationVariables>(UpdateColorSchemeDocument, options);
      }
export type UpdateColorSchemeMutationHookResult = ReturnType<typeof useUpdateColorSchemeMutation>;
export type UpdateColorSchemeMutationResult = Apollo.MutationResult<UpdateColorSchemeMutation>;
export type UpdateColorSchemeMutationOptions = Apollo.BaseMutationOptions<UpdateColorSchemeMutation, UpdateColorSchemeMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $title: String) {
  updateProject(id: $id, title: $title) {
    project {
      ...ProjectFragment
    }
    errors {
      ...ErrorFragment
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${ErrorFragmentFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const AssignedBookingsDocument = gql`
    query AssignedBookings {
  assignedBookings {
    id
    serviceDate
    location
    name
    acceptedAt
    instructions
    addToCalendar
  }
}
    `;

/**
 * __useAssignedBookingsQuery__
 *
 * To run a query within a React component, call `useAssignedBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignedBookingsQuery(baseOptions?: Apollo.QueryHookOptions<AssignedBookingsQuery, AssignedBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignedBookingsQuery, AssignedBookingsQueryVariables>(AssignedBookingsDocument, options);
      }
export function useAssignedBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignedBookingsQuery, AssignedBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignedBookingsQuery, AssignedBookingsQueryVariables>(AssignedBookingsDocument, options);
        }
export type AssignedBookingsQueryHookResult = ReturnType<typeof useAssignedBookingsQuery>;
export type AssignedBookingsLazyQueryHookResult = ReturnType<typeof useAssignedBookingsLazyQuery>;
export type AssignedBookingsQueryResult = Apollo.QueryResult<AssignedBookingsQuery, AssignedBookingsQueryVariables>;
export const AvailableServicersDocument = gql`
    query AvailableServicers($bookingAssignmentId: ID!) {
  availableServicers(bookingAssignmentId: $bookingAssignmentId) {
    ...ServicerFragment
  }
}
    ${ServicerFragmentFragmentDoc}`;

/**
 * __useAvailableServicersQuery__
 *
 * To run a query within a React component, call `useAvailableServicersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableServicersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableServicersQuery({
 *   variables: {
 *      bookingAssignmentId: // value for 'bookingAssignmentId'
 *   },
 * });
 */
export function useAvailableServicersQuery(baseOptions: Apollo.QueryHookOptions<AvailableServicersQuery, AvailableServicersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableServicersQuery, AvailableServicersQueryVariables>(AvailableServicersDocument, options);
      }
export function useAvailableServicersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableServicersQuery, AvailableServicersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableServicersQuery, AvailableServicersQueryVariables>(AvailableServicersDocument, options);
        }
export type AvailableServicersQueryHookResult = ReturnType<typeof useAvailableServicersQuery>;
export type AvailableServicersLazyQueryHookResult = ReturnType<typeof useAvailableServicersLazyQuery>;
export type AvailableServicersQueryResult = Apollo.QueryResult<AvailableServicersQuery, AvailableServicersQueryVariables>;
export const BookingDocument = gql`
    query Booking($id: ID!) {
  booking(id: $id) {
    ...BookingFragment
    project {
      ...ProjectFragment
    }
    lessee {
      ...LesseeFragment
    }
    revenues {
      ...RevenueFragment
    }
  }
}
    ${BookingFragmentFragmentDoc}
${ProjectFragmentFragmentDoc}
${LesseeFragmentFragmentDoc}
${RevenueFragmentFragmentDoc}`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
      }
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
        }
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const BookingAssignmentsDocument = gql`
    query BookingAssignments($bookingId: ID!) {
  bookingAssignments(bookingId: $bookingId) {
    ...BookingAssignmentFragment
  }
}
    ${BookingAssignmentFragmentFragmentDoc}`;

/**
 * __useBookingAssignmentsQuery__
 *
 * To run a query within a React component, call `useBookingAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAssignmentsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useBookingAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<BookingAssignmentsQuery, BookingAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAssignmentsQuery, BookingAssignmentsQueryVariables>(BookingAssignmentsDocument, options);
      }
export function useBookingAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAssignmentsQuery, BookingAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAssignmentsQuery, BookingAssignmentsQueryVariables>(BookingAssignmentsDocument, options);
        }
export type BookingAssignmentsQueryHookResult = ReturnType<typeof useBookingAssignmentsQuery>;
export type BookingAssignmentsLazyQueryHookResult = ReturnType<typeof useBookingAssignmentsLazyQuery>;
export type BookingAssignmentsQueryResult = Apollo.QueryResult<BookingAssignmentsQuery, BookingAssignmentsQueryVariables>;
export const BookingReasonsDocument = gql`
    query BookingReasons {
  bookingReasons {
    id
    title
  }
}
    `;

/**
 * __useBookingReasonsQuery__
 *
 * To run a query within a React component, call `useBookingReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingReasonsQuery(baseOptions?: Apollo.QueryHookOptions<BookingReasonsQuery, BookingReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingReasonsQuery, BookingReasonsQueryVariables>(BookingReasonsDocument, options);
      }
export function useBookingReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingReasonsQuery, BookingReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingReasonsQuery, BookingReasonsQueryVariables>(BookingReasonsDocument, options);
        }
export type BookingReasonsQueryHookResult = ReturnType<typeof useBookingReasonsQuery>;
export type BookingReasonsLazyQueryHookResult = ReturnType<typeof useBookingReasonsLazyQuery>;
export type BookingReasonsQueryResult = Apollo.QueryResult<BookingReasonsQuery, BookingReasonsQueryVariables>;
export const BookingsDocument = gql`
    query Bookings($searchParams: BookingSearchParams) {
  bookings(searchParams: $searchParams) {
    nodes {
      ...BookingFragment
      assignments {
        ...BookingAssignmentFragment
      }
      project {
        ...ProjectFragment
      }
      lessee {
        ...LesseeFragment
      }
    }
  }
}
    ${BookingFragmentFragmentDoc}
${BookingAssignmentFragmentFragmentDoc}
${ProjectFragmentFragmentDoc}
${LesseeFragmentFragmentDoc}`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useBookingsQuery(baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
      }
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
        }
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const BookingsByRangeDocument = gql`
    query BookingsByRange($searchParams: BookingSearchParams) {
  bookingsByRange(searchParams: $searchParams) {
    nodes {
      ...BookingFragment
      project {
        ...ProjectFragment
        colorScheme {
          ...ColorSchemeFragment
        }
      }
      lessee {
        ...LesseeFragment
      }
    }
  }
}
    ${BookingFragmentFragmentDoc}
${ProjectFragmentFragmentDoc}
${ColorSchemeFragmentFragmentDoc}
${LesseeFragmentFragmentDoc}`;

/**
 * __useBookingsByRangeQuery__
 *
 * To run a query within a React component, call `useBookingsByRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByRangeQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useBookingsByRangeQuery(baseOptions?: Apollo.QueryHookOptions<BookingsByRangeQuery, BookingsByRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsByRangeQuery, BookingsByRangeQueryVariables>(BookingsByRangeDocument, options);
      }
export function useBookingsByRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsByRangeQuery, BookingsByRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsByRangeQuery, BookingsByRangeQueryVariables>(BookingsByRangeDocument, options);
        }
export type BookingsByRangeQueryHookResult = ReturnType<typeof useBookingsByRangeQuery>;
export type BookingsByRangeLazyQueryHookResult = ReturnType<typeof useBookingsByRangeLazyQuery>;
export type BookingsByRangeQueryResult = Apollo.QueryResult<BookingsByRangeQuery, BookingsByRangeQueryVariables>;
export const LesseeDocument = gql`
    query Lessee($id: ID!) {
  lessee(id: $id) {
    ...LesseeFragment
  }
}
    ${LesseeFragmentFragmentDoc}`;

/**
 * __useLesseeQuery__
 *
 * To run a query within a React component, call `useLesseeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLesseeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLesseeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLesseeQuery(baseOptions: Apollo.QueryHookOptions<LesseeQuery, LesseeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LesseeQuery, LesseeQueryVariables>(LesseeDocument, options);
      }
export function useLesseeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LesseeQuery, LesseeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LesseeQuery, LesseeQueryVariables>(LesseeDocument, options);
        }
export type LesseeQueryHookResult = ReturnType<typeof useLesseeQuery>;
export type LesseeLazyQueryHookResult = ReturnType<typeof useLesseeLazyQuery>;
export type LesseeQueryResult = Apollo.QueryResult<LesseeQuery, LesseeQueryVariables>;
export const LesseesDocument = gql`
    query Lessees($searchParams: LesseeSearchParams) {
  lessees(searchParams: $searchParams) {
    ...LesseeFragment
  }
}
    ${LesseeFragmentFragmentDoc}`;

/**
 * __useLesseesQuery__
 *
 * To run a query within a React component, call `useLesseesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLesseesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLesseesQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useLesseesQuery(baseOptions?: Apollo.QueryHookOptions<LesseesQuery, LesseesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LesseesQuery, LesseesQueryVariables>(LesseesDocument, options);
      }
export function useLesseesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LesseesQuery, LesseesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LesseesQuery, LesseesQueryVariables>(LesseesDocument, options);
        }
export type LesseesQueryHookResult = ReturnType<typeof useLesseesQuery>;
export type LesseesLazyQueryHookResult = ReturnType<typeof useLesseesLazyQuery>;
export type LesseesQueryResult = Apollo.QueryResult<LesseesQuery, LesseesQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(id: $id) {
    ...FullProjectFragment
  }
}
    ${FullProjectFragmentFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectFullDocument = gql`
    query ProjectFull($id: ID!) {
  project(id: $id) {
    ...FullProjectFragment
  }
}
    ${FullProjectFragmentFragmentDoc}`;

/**
 * __useProjectFullQuery__
 *
 * To run a query within a React component, call `useProjectFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectFullQuery(baseOptions: Apollo.QueryHookOptions<ProjectFullQuery, ProjectFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFullQuery, ProjectFullQueryVariables>(ProjectFullDocument, options);
      }
export function useProjectFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFullQuery, ProjectFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFullQuery, ProjectFullQueryVariables>(ProjectFullDocument, options);
        }
export type ProjectFullQueryHookResult = ReturnType<typeof useProjectFullQuery>;
export type ProjectFullLazyQueryHookResult = ReturnType<typeof useProjectFullLazyQuery>;
export type ProjectFullQueryResult = Apollo.QueryResult<ProjectFullQuery, ProjectFullQueryVariables>;
export const ProjectTypesDocument = gql`
    query ProjectTypes {
  projectTypes
}
    `;

/**
 * __useProjectTypesQuery__
 *
 * To run a query within a React component, call `useProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectTypesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectTypesQuery, ProjectTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTypesQuery, ProjectTypesQueryVariables>(ProjectTypesDocument, options);
      }
export function useProjectTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTypesQuery, ProjectTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTypesQuery, ProjectTypesQueryVariables>(ProjectTypesDocument, options);
        }
export type ProjectTypesQueryHookResult = ReturnType<typeof useProjectTypesQuery>;
export type ProjectTypesLazyQueryHookResult = ReturnType<typeof useProjectTypesLazyQuery>;
export type ProjectTypesQueryResult = Apollo.QueryResult<ProjectTypesQuery, ProjectTypesQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($searchParams: ProjectSearchParams) {
  projects(searchParams: $searchParams) {
    ...FullProjectFragment
  }
}
    ${FullProjectFragmentFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const RevenueSourcesDocument = gql`
    query RevenueSources {
  revenueSources {
    ...RevenueSourceFragment
  }
}
    ${RevenueSourceFragmentFragmentDoc}`;

/**
 * __useRevenueSourcesQuery__
 *
 * To run a query within a React component, call `useRevenueSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRevenueSourcesQuery(baseOptions?: Apollo.QueryHookOptions<RevenueSourcesQuery, RevenueSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueSourcesQuery, RevenueSourcesQueryVariables>(RevenueSourcesDocument, options);
      }
export function useRevenueSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueSourcesQuery, RevenueSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueSourcesQuery, RevenueSourcesQueryVariables>(RevenueSourcesDocument, options);
        }
export type RevenueSourcesQueryHookResult = ReturnType<typeof useRevenueSourcesQuery>;
export type RevenueSourcesLazyQueryHookResult = ReturnType<typeof useRevenueSourcesLazyQuery>;
export type RevenueSourcesQueryResult = Apollo.QueryResult<RevenueSourcesQuery, RevenueSourcesQueryVariables>;
export const ServicersDocument = gql`
    query Servicers($searchParams: ServicerSearchParams) {
  servicers(searchParams: $searchParams) {
    ...ServicerFragment
  }
}
    ${ServicerFragmentFragmentDoc}`;

/**
 * __useServicersQuery__
 *
 * To run a query within a React component, call `useServicersQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicersQuery({
 *   variables: {
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useServicersQuery(baseOptions?: Apollo.QueryHookOptions<ServicersQuery, ServicersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicersQuery, ServicersQueryVariables>(ServicersDocument, options);
      }
export function useServicersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicersQuery, ServicersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicersQuery, ServicersQueryVariables>(ServicersDocument, options);
        }
export type ServicersQueryHookResult = ReturnType<typeof useServicersQuery>;
export type ServicersLazyQueryHookResult = ReturnType<typeof useServicersLazyQuery>;
export type ServicersQueryResult = Apollo.QueryResult<ServicersQuery, ServicersQueryVariables>;
export const TableDisplayDocument = gql`
    query TableDisplay($entity: String!) {
  tableDisplay(entity: $entity) {
    labelText
    headers {
      text
      sortable
      key
    }
    filterBy {
      filters {
        inputType
        labelText
        options {
          async {
            filter
            query
          }
          options {
            label
            value
          }
          datetime {
            defaultRelativeValue
            defaultOperator
            defaultDirection
          }
        }
        variable
      }
      filterSets {
        labelText
        filters {
          inputType
          variable
          options {
            valueType
            operator
            value
            direction
            valueArray
            valueSingle
          }
        }
      }
    }
    rows {
      rowClickable
      cells {
        key
        component
        href
        associatedEntity {
          displayType
          entity {
            headerText
            href
            id
            queryName
          }
        }
        component
      }
      actions {
        action
        mutation {
          mutationKey
          variables
        }
        text
      }
    }
  }
}
    `;

/**
 * __useTableDisplayQuery__
 *
 * To run a query within a React component, call `useTableDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableDisplayQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useTableDisplayQuery(baseOptions: Apollo.QueryHookOptions<TableDisplayQuery, TableDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableDisplayQuery, TableDisplayQueryVariables>(TableDisplayDocument, options);
      }
export function useTableDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableDisplayQuery, TableDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableDisplayQuery, TableDisplayQueryVariables>(TableDisplayDocument, options);
        }
export type TableDisplayQueryHookResult = ReturnType<typeof useTableDisplayQuery>;
export type TableDisplayLazyQueryHookResult = ReturnType<typeof useTableDisplayLazyQuery>;
export type TableDisplayQueryResult = Apollo.QueryResult<TableDisplayQuery, TableDisplayQueryVariables>;